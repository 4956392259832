import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import useGetLogo from "./hooks/use-logo";
import testLogo from "src/images/levelspurple.png";
const Logo = ({ disabledLink = false }) => {
  const { appLogo, sideBarLogo } = useGetLogo();
  if (disabledLink) {
    return (
      <img
        style={{ width: "150px", height: "auto", maxHeight: "64px" }}
        src={appLogo ? appLogo : testLogo}
      />
    );
  }

  return (
    <RouterLink to="/">
      <img
        style={{ width: "150px", height: "auto" }}
        src={sideBarLogo ? sideBarLogo : testLogo}
      />
    </RouterLink>
  );
};

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
